<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Card</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="isLoggedIn" class="ion-padding" ref="content">
      <ion-card v-if="isLoggedIn && selectedCard">
        <ion-card-header v-if="selectedCard" class="ion-no-padding">
          <ion-item lines="none">
            <ion-card-title v-if="!selectedCard.pointPackages">{{ $t('creditoncard')}} {{ selectedCard.cardamount }} </ion-card-title>
            <ion-card-title v-if="selectedCard.pointPackages">{{ $t('pointsoncard')}} {{ selectedCard.cardpoints }} </ion-card-title>
            <ion-button v-if="selectedCard.creditPaymentEnabled &&  hasPaymentMethod /*&& (isSuccessfullBraintreePaypalAuthorized || isSuccessfullBraintreeCreditCardAuthorized*)*/" @click="recharge()" fill="clear">
                <ion-icon style="zoom: 1.4" color="success" :ios="addCircleOutline" :md="addCircleOutline"></ion-icon>
              </ion-button>
          </ion-item>
          <ion-label v-if="selectedCard.creditPaymentEnabled && !hasPaymentMethod" class="ion-margin ion-no-padding" color="warning">{{ $t('noPaymentMethod') }}</ion-label>
        </ion-card-header>
        <ion-card-content>
          <!-- <ion-label v-if="selectedCard && selectedCard.creditPaymentEnabled && !isSuccessfullBraintreePaypalAuthorized && !isSuccessfullBraintreeCreditCardAuthorized">{{ $t('noPaymentMethod') }}</ion-label>
              <ion-grid v-if="selectedCard && selectedCard.creditPaymentEnabled && (isSuccessfullBraintreePaypalAuthorized || isSuccessfullBraintreeCreditCardAuthorized)">
                <ion-row>
                  <ion-col size="auto">
                    <ion-checkbox class="ion-no-padding" color="primary"  
                        :checked="selectedCard.doRecharge" @ionChange="selectionChangedDoRecharge($event.target.checked)">
                      </ion-checkbox>
                  </ion-col>
                  <ion-col size="auto">
                    <ion-label>{{ $t("automaticRechargeBeyond") }}:</ion-label>
                  </ion-col>
                  <ion-col size="auto">
                    <ion-label>{{ selectedCard.rechargeLimit }} {{ selectedCard.currencySymbol }}</ion-label>
                  </ion-col>
                  <ion-col size="auto" v-if="selectedCard.doRecharge && selectedCard.rechargeLimit > 0">
                    <ion-icon @click="checkRechargeLimit(selectedCard.rechargeLimit - 50)" style="zoom: 1.4" color="dangerlight" :ios="removeCircleOutline" :md="removeCircleOutline"></ion-icon>
                  </ion-col>
                  <ion-col size="auto" v-if="selectedCard.doRecharge && selectedCard.rechargeLimit < 500">
                    <ion-icon @click="checkRechargeLimit(selectedCard.rechargeLimit + 50)" style="zoom: 1.4" color="success" :ios="addCircleOutline" :md="addCircleOutline"></ion-icon>
                  </ion-col>
                </ion-row>
                <ion-row v-if="selectedCard.doRecharge">
                  <ion-col size="auto">
                    <ion-label>{{ $t("rechargeBy") }}:</ion-label>
                  </ion-col>
                  <ion-col size="auto">
                    <ion-label>{{ selectedCard.rechargeAmount }} {{ selectedCard.currencySymbol }}</ion-label>
                  </ion-col>
                  <ion-col size="auto" v-if="selectedCard.doRecharge && selectedCard.rechargeLimit > 0">
                    <ion-icon @click="rechargeAmount(selectedCard.rechargeAmount - 50)" style="zoom: 1.4" color="dangerlight" :ios="removeCircleOutline" :md="removeCircleOutline"></ion-icon>
                  </ion-col>
                  <ion-col size="auto" v-if="selectedCard.doRecharge && selectedCard.rechargeLimit < 500">
                    <ion-icon @click="rechargeAmount(selectedCard.rechargeAmount + 50)" style="zoom: 1.4" color="success" :ios="addCircleOutline" :md="addCircleOutline"></ion-icon>
                  </ion-col>
                </ion-row>
              </ion-grid>-->

              <ion-grid class="ion-no-padding" v-if="selectedCard">
                <ion-row>
                  <ion-label  v-if="!selectedCard.pointPackages" style="margin-left: 15px; font-size: medium;" class="ion-text-wrap">{{ $t('bonuspoints') }}: {{ selectedCard.cardpoints }}</ion-label>
                </ion-row>
                <ion-row v-if="selectedCard.extraCredit">
                  <ion-label style="margin-left: 15px; font-size: medium;" class="ion-text-wrap">{{ selectedCard.extraCredit.name }}: {{ selectedCard.extraCredit.amountleft }} {{ $t('of' ) }} {{ selectedCard.extraCredit.amount }} ({{ selectedCard.extraCredit.validFrom }} - {{ selectedCard.extraCredit.validTo }})</ion-label>
                </ion-row>
                <ion-row v-if="selectedCard.generalPackages.length > 0">
                  <ion-label style="margin-left: 15px;font-weight: bold;" class="ion-text-wrap">{{ $t('generalPackage') }}</ion-label>
                </ion-row>
                <ion-row v-for="(item, index) in selectedCard.generalPackages" :key="index" :value="item">
                  <ion-label style="margin-left: 15px; font-size: medium;" class="ion-text-wrap">{{ item.amountballs }} {{ $t('frequency' + item.frequency ) }} {{ $t('packageAvailable' ) }} {{ (item.amountballs - item.ballsTaken) }}</ion-label>
                </ion-row>
                <ion-row v-if="selectedCard.personalPackages.length > 0">
                  <ion-label style="margin-left: 15px;font-weight: bold;" class="ion-text-wrap">{{ $t('personalPackage') }}</ion-label>
                </ion-row>
                <ion-row v-for="(item, index) in selectedCard.personalPackages" :key="index" :value="item">
                  <ion-label style="margin-left: 15px; font-size: medium;" class="ion-text-wrap">{{ item.amountballs }} {{ $t('frequency' + item.frequency ) }} {{ $t('packageAvailable' ) }} {{ (item.amountballs - item.ballsTaken) }}</ion-label>
                </ion-row>
              </ion-grid>

              <!-- ion-item v-if="selectedCard && selectedBallmachine" lines="none">
                <ion-select interface="popover" v-model="selectedBallmachine">
                  <ion-select-option v-for="(item, index) in selectedCard.ballmachines" :key="index" :value="item">{{ item.name }}</ion-select-option>          
                </ion-select>
              </ion-item>
              <ion-item v-if="selectedCard && selectedBallmachine">
                <ion-button v-if="selectedCard" @click="requestAppButton()">{{ $t('getBalls') }}</ion-button>
              </ion-item>
              <ion-item v-if="counting" lines="full">
                <vue-countdown slot="start" @progress="checkTime" :time="waitTime" v-slot="{ seconds }">
                  {{ $t('ballmachineTimerRunning') }}  <ion-label :color="clockColor">{{ seconds }} {{ $t('seconds') }}</ion-label>
                </vue-countdown>
              </ion-item>-->
        </ion-card-content>
      </ion-card>
      <ion-card v-if="isLoggedIn">
        <ion-card-header>
            <ion-card-title>{{ $t('assignedCards') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-label  style="margin-left: 15px;" v-if="!cards || cards.length <= 0" class="ion-text-wrap">{{ $t('noCardFound') }}</ion-label>
          <ion-item v-if="selectedCard">
            {{ $t('#') }}
            <ion-select interface="popover" v-model="selectedCard">
              <ion-select-option v-for="(item, index) in cards" :key="index" :value="item">{{ item.name }} {{ item.uid }}</ion-select-option>          
            </ion-select>
            <ion-button fill="clear" @click="showHistory()">
             {{ $t('cardHistory') }}
            </ion-button>
          </ion-item>
          <ion-item v-if="selectedCard">
            <ion-label style="margin-left: 15px;" class="ion-text-wrap">{{ $t('name') }}: </ion-label>
            <ion-input type="text" :placeholder="$t('name')" v-model="selectedCard.name"></ion-input>
            <ion-button @click="saveCardName()" fill="clear">
                <ion-icon style="zoom: 1.4" color="success" :ios="saveOutline" :md="saveOutline"></ion-icon>
            </ion-button>
          </ion-item>
          <ion-item v-if="selectedCard && selectedCard.valid != ''">
            <ion-label style="margin-left: 15px;" class="ion-text-wrap">{{ $t('valid') }}: {{ selectedCard.valid }}</ion-label>
          </ion-item>
          <ion-item v-if="selectedCard && selectedCard.valid == ''">
            <ion-label style="margin-left: 15px;" class="ion-text-wrap">{{ $t('valid') }}: {{ $t('unlimited') }}</ion-label>
          </ion-item>
          <ion-item v-if="selectedCard">
            <ion-label style="margin-left: 15px;" :color="selectedCard.status == 1 ? 'warning' : 'success'" class="ion-text-wrap">{{ $t('status') }}: {{ selectedCard.status == 1 ? $t('locked') : $t('active') }}</ion-label>
          </ion-item>
          <ion-list v-if="selectedCard">
            <ion-list-header lines="inset">
              <ion-label>{{ $t('permissions') }}</ion-label>
            </ion-list-header>
            <ion-grid style="margin-left: 15px;">
              <ion-row v-for="(item, index) in selectedCard.permissions" :key="index" :value="item">
                <ion-col size="auto">{{ item.name }}</ion-col>
              </ion-row>
            </ion-grid>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonButton, IonContent, IonHeader, IonTitle, toastController,IonCardContent, IonTextarea, IonSearchbar,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonSelect, IonSelectOption,
IonGrid, IonCol, IonRow, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, alertController, modalController,loadingController,
IonListHeader, IonAvatar, isPlatform } from '@ionic/vue'
import { defineComponent, ref, watch, computed } from 'vue'
import { removeCircleOutline, addCircleOutline, listOutline, saveOutline, timeOutline } from 'ionicons/icons'
import changeDoRechargeMutationFile from '../graphql/changeDoRecharge.mutation.gql'
import saveCardNameMutationFile from '../graphql/saveCardName.mutation.gql'
import requestAppButtonMutationFile from '../graphql/requestAppButton.mutation.gql'
import cardsQueryFile from '../graphql/getCards.query.gql'
import AddCreditDialog from './dialogs/AddCreditDialog'
import CardHistoryDialog from './dialogs/CardHistoryDialog'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import * as dayjs from 'dayjs';
import store from '../main'
import translate from '@/plugins/translate'
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'Friends',
  components: { IonPage, IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonCardContent, IonSelect, IonSelectOption, IonTextarea, IonSearchbar,
                IonListHeader, IonAvatar
              },
  props: ['propsData'],
  setup() {
    let selectedCard = ref()
    let selectedBallmachine = ref()
    let loadingSpinner = null
    let counting = ref(false)
    let clockColor = ref('success')
    let waitTime = ref(15 * 1000)
    const isLoggedIn = computed(() => store.getters.isLoggedIn ) 
    const clubInfo = ref(getClubInfo())

    const { mutate: changeDoRechargeMutation } = useMutation(changeDoRechargeMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: saveCardNameMutation } = useMutation(saveCardNameMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: requestAppButtonMutation } = useMutation(requestAppButtonMutationFile, { fetchPolicy: 'no-cache' });

    const { onResult, result: cardsResult, loading: cardsLoading, refetch: cardsRefetch} = useQuery(cardsQueryFile, null, { fetchPolicy: 'no-cache', enabled: isLoggedIn.value })
    const cards = useResult(cardsResult, null, data => data.getCards.cards)
    onResult(queryResult => {
      if (queryResult.data && queryResult.data.getCards && queryResult.data.getCards.cards && queryResult.data.getCards.cards.length > 0) {
          selectedCard.value = queryResult.data.getCards.cards[0]
          if (selectedCard.value.ballmachines.length > 0)
            selectedBallmachine.value = selectedCard.value.ballmachines[0]
      }
      else
        selectedCard.value = null
    })
    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!cardsLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(cardsLoading, (cardsLoading, prevLoading) => {
      if (!cardsLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (cardsLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!cardsLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })

    watch(selectedCard, (selectedCard, prevselectedCard) => {
      if (selectedCard && selectedCard.ballmachines.length > 0)
        selectedBallmachine.value = selectedCard.ballmachines[0]
      else
        selectedBallmachine.value = null
    })

    return {
      changeDoRechargeMutation, removeCircleOutline, addCircleOutline, cards, selectedCard, listOutline, saveOutline, saveCardNameMutation, cardsRefetch, requestAppButtonMutation, selectedBallmachine, counting, timeOutline, clockColor, waitTime, isLoggedIn, clubInfo
    }
  },
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
      hasPaymentMethod : function() { return this.clubInfo && this.clubInfo.clubInfo && (this.clubInfo.clubInfo.canPaypal || this.clubInfo.clubInfo.canLastschrift ) },
  },
  methods: {
    checkTime: function (data) {
      if (data.seconds <= 1) {
        this.cardsRefetch()
        this.clockColor = 'success'
        this.counting = false
      }
      if (data.seconds <= 5)
        this.clockColor = 'danger'
      else if (data.seconds <= 10)
        this.clockColor = 'warning'
      else
        this.clockColor = 'success'
    },    
    async requestAppButton() {
      this.clockColor = 'success'
      this.requestAppButtonMutation({ ccid: this.selectedBallmachine.ccid, uid: this.selectedCard.uid })
      .then(res => {
        if (res.data.requestAppButton.success == true) {
          this.waitTime = res.data.requestAppButton.confirmWaitingTime * 1000
          this.counting = true
        }
        else if (!this.counting) {
          toastController
            .create({
              message: translate(res.data.requestAppButton.errorCode),
              duration: 5000,
              color: 'danger',
            }).then(res => res.present())
          this.counting = false
        }
      })
      .catch(err => {
        if (!this.counting) {
          toastController
            .create({
              message: translate('appButtonError'),
              duration: 5000,
              color: 'danger',
            }).then(res => res.present())
          this.counting = false
        }
      }) 
    },
    async saveCardName() {
      this.saveCardNameMutation({ uid: this.selectedCard.uid, name: this.selectedCard.name })
      .then(res => {
        this.cardsRefetch()
      })
      .catch(err => {
      }) 
    },
    async showHistory() {
      const modal = await modalController
        .create({
          component: CardHistoryDialog,
          componentProps: {
            propsData: {
              selectedCard: this.selectedCard,
            }
          },
        })
      return modal.present();
    },
    async selectionChangedDoRecharge(value) {
      this.changeDoRechargeMutation({ doRecharge: value, rechargeLimit: parseFloat(this.selectedCard.rechargeLimit), rechargeAmount: parseFloat(this.selectedCard.rechargeAmount), uid: this.selectedCard.uid })
      .then(res => {
          this.cardsRefetch()
      })
      .catch(err => {
        console.log(err)
      }) 
    },
    async recharge() {
      const modal = await modalController
        .create({
          component: AddCreditDialog,
          componentProps: {
            propsData: {
              selectedCard: this.selectedCard,
            }
          },
        })
        modal.onDidDismiss()
          .then((data) => {
            if (data.data) {
              this.cardsRefetch()
            }
          })
      return modal.present();
    },
    rechargeAmount(value) {
      if (value < 0) {
        this.cardsRefetch()
        return
      }
      if (value > 500) {
        this.cardsRefetch()
        return
      }
      this.changeDoRechargeMutation({ doRecharge: this.selectedCard.doRecharge, rechargeLimit: parseFloat(this.selectedCard.rechargeLimit), rechargeAmount: parseFloat(value), uid: this.selectedCard.uid })
      .then(res => {
        this.cardsRefetch()
      })
      .catch(err => {
      }) 
    },
    checkRechargeLimit(value) {
      if (value < 0) {
        this.cardsRefetch()
        return
      }
      if (value > 500) {
        this.cardsRefetch()
        return
      }
      this.changeDoRechargeMutation({ doRecharge: this.selectedCard.doRecharge, rechargeLimit: parseFloat(value), rechargeAmount: parseFloat(this.selectedCard.rechargeAmount), uid: this.selectedCard.uid })
      .then(res => {
        this.cardsRefetch()
      })
      .catch(err => {
      }) 
    },
  }
});
</script>

<style scoped>


</style>